import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { FallbackComponent } from '@/components/FallbackComponent';
import { BookingPage } from '@/pages/BookingPage/BookingPage';
import { FullBookingPage } from '@/pages/FullBookingPage';

function App() {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <Router>
      <Sentry.ErrorBoundary fallback={() => <FallbackComponent />}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />

          <main className="container">
            <Routes>
              <Route path="/online/:id" element={<FullBookingPage />} />
              <Route path="/" element={<BookingPage />} />
              {/* Add more routes as needed */}
            </Routes>
          </main>

          <Toaster position="bottom-center" />
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </Router>
  );
}

export default App;
