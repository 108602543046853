import './DearDocFooter.css';

export const DearDocFooter = () => {
  return (
    <div className="footer-container">
      <span>
        Powered by&nbsp;
        <img
          className="footer-logo-img"
          src="https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/DearDoc-logo-dark.png?alt=media&token=24c4aed1-0cc9-46b9-82da-16f9a06c2064"
        ></img>
      </span>
    </div>
  );
};
