import './PatientInformationForm.css';

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@/components/Button';

type CustomerData = {
  [key: string]: {
    name: string;
    logo: string;
  };
};

const customerData: CustomerData = {
  DhhdTKCHP2tUkDnaNviO: {
    name: 'DearDoc',
    logo: 'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/DearDoc-logo-dark.png?alt=media&token=24c4aed1-0cc9-46b9-82da-16f9a06c2064',
  },
  DP153yGc48gzuAlCNvEP: {
    name: 'Kids Dental Castle',
    logo: 'https://lirp.cdn-website.com/53b42914/dms3rep/multi/opt/LOGO-338w.jpg',
  },
  OyGqFiks9p65kCcOEiM1: {
    name: 'New Image General Dentistry',
    logo: 'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/dentristre.png?alt=media&token=bc968f39-60c4-4669-b791-796bf5fdde29',
  },
  M4fVFGK92sR0GtUTj8Qz: {
    name: 'Frisco Modern Dentistry',
    logo: 'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/high_dentrist.png?alt=media&token=75dd0a2a-b807-4214-8394-f367b57e14b0',
  },
};

export const PatientInformationForm = () => {
  const { id } = useParams(); // This extracts the `id` from the path
  const navigate = useNavigate(); // Step 2: Get the navigate function
  console.log(customerData);
  const [customer, setCustomer] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    console.log('Submitting customer data:', customer);
    navigate(
      `/?location=${id}&name=${customer.name}&email=${customer.email}&phone=${customer.phone}`,
    );
  };

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const validateForm = () => {
    const newErrors = { name: '', phone: '', email: '' };
    let isValid = true;

    if (!customer.name) {
      newErrors.name = 'Name is required.';
      isValid = false;
    }

    if (!customer.phone || customer.phone.length !== 10) {
      newErrors.phone = 'Phone must be 10 digits.';
      isValid = false;
    }

    if (!customer.email || !/\S+@\S+\.\S+/.test(customer.email)) {
      newErrors.email = 'Email is invalid.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const logoImageSource = id && id in customerData ? customerData[id]?.logo :
    'https://firebasestorage.googleapis.com/v0/b/deardoc-appointment-manager.appspot.com/o/DearDoc-logo-dark.png?alt=media&token=24c4aed1-0cc9-46b9-82da-16f9a06c2064';

  return (
    <>
      <div className="form-container">
        <div className="form-centered-container">
          <img className="form-logo" src={logoImageSource}></img>
          <span className="form-strong-title">Schedule your appointment </span>
          <span className="form-title">Enter Patient Information</span>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <div className="input-container">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              placeholder={'Enter your full name'}
              name="name"
              value={customer.name}
              onChange={handleChange}
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="input-container">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder={'Enter your phone number'}
              value={customer.phone}
              onChange={handleChange}
            />
            {errors.phone && <div className="error">{errors.phone}</div>}
          </div>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder={'Enter your email address'}
              value={customer.email}
              onChange={handleChange}
            />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <Button className={'btn btn--active'} onClick={handleSubmit}>
            Continue &rarr;
          </Button>
        </form>
      </div>
    </>
  );
};
