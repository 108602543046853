import './FullBookingPage.css';

import { DearDocFooter } from '@/components/DearDocFooter';
import { PatientInformationForm } from '@/modules/PatientInformationForm';

export const FullBookingPage = () => {
  return (
    <>
      <PatientInformationForm />
      <DearDocFooter />
    </>
  );
};
